@import '../../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';

.mpd-seating-booking-tile {
  &__container {
    text-align: center;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid $secondaryLightGray;
    margin: 20px 10px 0 10px;
  }

  &__customer {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0;

    &--clickable {
      text-decoration: underline;
    }
  }

  &__price {
    margin-top: 20px;
  }

  &__action-cell {
    margin-top: auto;

    a {
      font-weight: bold;
      text-transform: uppercase;
      font-size: $font-size-base;
    }
  }

  &__status-row {
    display: flex;
    flex-direction: row;
  }

  &__status-icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__comment {
    margin-top: 20px;
    font-size: $font-size-small;
  }

  &__origin {
    margin-top: 5px;
    font-size: $font-size-small;
  }
}
